<template>
    <div class="header">
        <transition name="left">
            <div class="left" v-if="show_elements">
                <img class="logo" src="../assets/logo-web.png" :style="{
                    width: logoSize.width, 
                    height: logoSize.height, 
                    'margin-top': logoSize.top
                    }" alt="">
            </div>
        </transition>
        <transition name="right">     
            <Navbar v-if="show_elements" />
        </transition>

    </div>
</template>

<script>
    import Navbar from '@/components/Navbar.vue'
    export default {
        components: {
            Navbar
        },
        data() {
            return {
                show_elements: false
            }
        },
        computed: {
            logoSize() {
              switch (this.$vuetify.breakpoint.name) {
                case 'xs': return {width: '150px', height: '50px', left: '-4%', top: '6%'}
                case 'sm': return {width: '160px', height: '50px', left: '-2%', top: '13%'}
                case 'md': return {width: '180px', height: '60px', left: '-8%', top: '11%'}
                case 'lg': return {width: '220px', height: '70px', left: '-10%', top: '5.5%'}
                case 'xl': return {width: '200px', height: '60px', left: '-16%', top: '10%'}
                  }
                return 1
            }
        },
        beforeMount() {
            setTimeout(() => {
                this.show_elements = true
            }, 2000);
        },
        
    }
</script>

<style scoped>
.header {
    position: absolute;
    display: flex;
    justify-content: space-between;
    z-index: 99999;
    width: 100%;
    height: 50px;
    top: 0;
    left: 0;
}
@keyframes left-enter {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes right-enter {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.left-enter-active {
  animation: left-enter .5s ease-in;
}
.right-enter-active {
  animation: right-enter .5s ease-in;
}
.left {
    margin-left: -8px;
    margin-top: -10px;
}


</style>