<template>
    <div class="navbar wrapper green borderXwidth font-calistoga " v-if="$vuetify.breakpoint.width > 1264">
        <a @click="scrollTo('section1')">Strona główna</a>
        <a @click="scrollTo('section2')">Usługi</a>
        <a @click="scrollTo('section3')">Galeria</a>
        <a @click="scrollTo('section4')">Kontakt</a>
    </div>
    <div v-else data-app class="dropdown">
        <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          large
          color="#826251"
          dark
          v-bind="attrs"
          v-on="on"
          outlined
        >
        <span v-if="!changeButton"> menu </span>
        <v-icon class="menu-icon">
            mdi-menu
        </v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item class="list-item"> 
            <v-list-item-title class="list-item-title" @click="scrollTo('section1')">Strona główna</v-list-item-title>
        </v-list-item>
        <v-list-item class="list-item">
            <v-list-item-title class="list-item-title" @click="scrollTo('section2-mobile')">Usługi</v-list-item-title>
        </v-list-item>
        <v-list-item class="list-item">
            <v-list-item-title class="list-item-title" @click="scrollTo('section3-mobile')">Galeria</v-list-item-title>
        </v-list-item>
        <v-list-item class="list-item">
            <v-list-item-title class="list-item-title" @click="scrollTo('section4-mobile')">Cennik</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    </div>
    
</template>

<script>
    export default {
        computed: {
            fontSize() {
                switch (this.$vuetify.breakpoint.name) {
                case 'xs': return 17
                case 'sm': return 23
                case 'md': return 28
                case 'lg': return 30
                case 'xl': return 36
                }
                return 30
            },
            changeButton() {
                return this.$vuetify.breakpoint.width < 840 ? true : false
            }
        },
        methods: {
            scrollTo(to) {
                document.getElementById(to).scrollIntoView({behavior: "smooth"})
            } 
        },
    }
</script>

<style scoped>
    @import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200&display=swap');
    .navbar {
        margin-top: 10px;
        display: flex;
        flex-direction: row;
    }
    div.wrapper
    {
        font-family: var(--font-1-basic);
    }

    div.wrapper a
    {
        color: var(--brown30);
        text-decoration: none;
        font: 18px var(--font-2-basic);
        color: #576096 !important;
        margin: 0px 0px;
        height: 50px;
        padding: 10px 10px;
        position: relative;
        z-index: 0;
        cursor: pointer;
        white-space: nowrap;
    }
div.borderXwidth a:before, div.borderXwidth a:after
{
    position: absolute;
    opacity: 0;
    width: 0%;
    height: 1px !important;
    content: '';
    background: #576096;
    transition: all 0.3s;
    color: #576096 !important;
}

div.borderXwidth a:before
{
    left: 0px;
    top: 5px;
}

div.borderXwidth a:after
{
    right: 0px;
    bottom: 5px;
}

div.borderXwidth a:hover:before, div.borderXwidth a:hover:after
{
    opacity: 1;
    width: 100%;
}

.dropdown {
    display: flex;
    margin-top: 1%;
    margin-right: 1%;
}
.menu-icon {
    font-size: 23px !important;
}
.list-item {
    background-color: white;
    transition: all 0.2s ease-in;
}
.list-item:hover {
    background-color: rgb(211, 211, 211);
    cursor: pointer;
}
.list-item-title {
    color: var(--brown30) !important;
}


</style>