<template>
  <div id="section1">
    <Header />
    <div class="desktop-menu" v-if="!isMobile">
      <Carousel class="carousel-container" @carousel-loaded="changeColor()" />
    </div>
    <div class="mobile-menu" v-else>
      <Carousel class="carousel-container" @carousel-loaded="changeColor()" />
    </div>
    <AboutDesktop v-show="colored" v-if="!isMobile"/>
    <AboutMobile v-else />
  </div>  
</template>

<script>
import Carousel from './components/Carousel.vue'
import AboutDesktop from './components/AboutDesktop.vue'
import AboutMobile from './components/AboutMobile.vue'
import Header from './components/Header.vue'

export default {
  name: 'App',
  metaInfo: {

    title: 'LB Wykończenia',

    meta: [
      {'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8'},
      {name: 'viewport', content: 'width=device-width, initial-scale=1'},
      {name: 'description', content: 'Spraw, aby Twój dom wyglądał jak najlepiej dzięki LB-Wykończenia. Mamy ponad 5-letnie doświadczenie w dostarczaniu pięknych, trwałych i solidnych wykończeń domów i mieszkań.'},
      {name: 'keywords', content: 'Wykończenia kraków, Wykończenia, Remonty, Remonty Kraków, LB, LB-Wykończenia, LB Wykończenia, LBWykończenia, Remonty LB'}
    ],
    link: [
    {rel: 'canonical', href: 'https://www.lb-wykonczenia.pl'}
  ]
  },
  components: {
    Carousel,
    AboutDesktop,
    AboutMobile,
    Header
  },
  data() {
    return {
      colored: false,
      window: null
    }
  },
  methods: {
    changeColor() {
      this.colored = true
    }
  },
  computed: {
    isMobile() {
      return this.window < 500
    }
  },
  beforeMount() {
        this.window = window.innerWidth
        window.addEventListener('resize', () => {
            this.window = window.innerWidth
        })
  }, 
  

}
</script>

<style>
@import 'https://fonts.googleapis.com/css?family=Raleway';
@import url('https://fonts.googleapis.com/css2?family=Calistoga&family=Inconsolata:wght@200&display=swap');


:root {
  --font-1-basic: Raleway;
  --font-2-basic: 'Calistoga', cursive;
  --blue30: #5c9ead;
  --brown30: #826251;
  --brown40: #5F5F5F;
  --blue20: #274C77;
}
.font-calistoga {
  font-family: 'Calistoga', cursive;
}
.font-raleway {
  font-family: Raleway
}
body {
  margin: 0;
  background-color: rgb(255, 255, 255);
}
.main-container {
  position: relative;
  height: auto;
}

.desktop-menu {
  position: relative;
  min-height: 660px;
  z-index: 50;

}
.mobile-menu {
  position: relative;
  height: 40vh;
  z-index: 50;
}

.body-wrapper {
  height: calc(100%-130px);
}
.p-galleria .p-galleria-item-nav {
  height: 1em !important;
  color: #6c757d !important;
}

/* .section_1_enter-enter-active {
  animation: section-1-enter 0.4s ease-in
}

@keyframes section-1-enter {
  from {
    opacity: 0%;
  }
  to {
    opacity: 100%;
  }
}

.main-container {
  position: relative;
  height: auto;
}

.navbar { 
  position: absolute;
}
.menu {
  position: relative;
  height: 70vh;
  z-index: 50;

}
.body-wrapper {
  height: calc(100%-130px);
}

.section1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  bottom: 130px;
  height: 800px;
  width: 100%;
  transition: all 0.3s ease-in;
  background: linear-gradient(to top left, var(--blue30) calc(50% - 1px), rgb(255, 255, 255) , rgba(255, 255, 255, 0.979) calc(50% + 1px) );
}

.section2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-top: -130px;
  height: 80vh;
  width: 100%;
  background: linear-gradient(to top right, rgb(255, 255, 255) calc(50% - 1px), rgb(255, 255, 255) , var(--blue30) calc(50% + 1px) )
}
.section3 {
  position: relative;
  background: linear-gradient(0deg, var(--blue20), rgb(252, 252, 252) 80%);
  height: 80vh;
  width: 100%;
}
.shape {
  clip-path: polygon(0 6%, 100% 0, 100% 94%, 0% 100%);
  background-color: var(--brown30);
  height: 80vh;
}
.footer {
  position: absolute;
  background-color: var(--blue20);
  height: 50vh;
  width: 100%;
}
.container1 {
  position: relative;
  top:30px;
  left:0;
  width: 200px;
  height: 200px;
  background-color: aqua;
  z-index: 1;
}
.box {
  position: absolute;
  width: 100px;
  height: 100px;
  background-color: aqua;
  top:30px;
  left: 20px;
}
.bottom-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 30vw;
  margin-right: 3%;
  margin-top: 18%;
}
.section2 .bottom-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 30vw;
  margin-bottom: 45%;
}
.top-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 30vw;
  word-break: keep-all;
  margin-bottom: 10%;
  margin-left: 5%;
}

.section2 .top-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 30vw;
  margin-top: 40%;
  margin-bottom: 25%;
}
.top-title {
  color: var(--brown30);
  font-size: 30px;
  margin-bottom: 1%;
}
.title {
  color: rgb(255, 255, 255);
  font-size: 50px;
  margin-bottom: 1%;
}
.top-content {
  margin-top: 2%;
  color: var(--brown30);
  font-size: 16px;
}
.content {
  margin-top: 2%;
  color: rgb(255, 255, 255);
  font-size: 25px;
}
.hr {
  transform: rotate(-0.84deg);
}

@media (max-width: 530px) {
  .top-wrapper {
    margin-bottom: 35%;
  }
  .top-content {
    font-size: 15px;
  }
  .section2 .bottom-wrapper {
    margin-bottom: 100% !important;
  }
}
@media (max-width: 840px) {
  .section2 .bottom-wrapper {
    margin-bottom: 60%;
  }
}
@media (max-width: 720px) {
  .section2 .top-wrapper {
    margin-top: 60%;
  }
  .bottom-wrapper {
    margin-bottom: -20%;
  }
} */
</style>
