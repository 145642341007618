<template>
    <div>
        <div class="backdrop" v-if="open" @click="$emit('close')"> </div>
        <transition name="modal">
            <dialog open v-if="open">
                <div class="container">
                    <div class="title">
                        <span class="title-label"> Galeria realizacji nr. {{gallery}} </span>
                    </div>
                    <hr>
                    <div class="content">
                         <lingallery :iid.sync="currentId" :width="width" :height="height" :items="items" />
                    </div>
                </div>    
            </dialog>
        </transition>
    </div>
</template>

<script>
    export default {
        props: {
            open: Boolean,
            gallery: Number,
            photos: Number 
        },
        data() {
            return {
                img1: null,
                width: 600,
                height: 400,
                items: [],
                currentId: null,
                window: null
                }
        },
        beforeMount() {
            // this.img1 = require('../assets/job_1/img1_2.jpg')
            for (let index = 0; index < this.photos; index++) {
                this.items[index] = {
                    src: require(`@/assets/job_${this.gallery}/img${this.gallery}_${index + 1}.jpg`),
                    thumbnail: require(`@/assets/job_${this.gallery}/img${this.gallery}_${index + 1}.jpg`),
                    id: 'photo' + index
                }
                
            }
            this.window = window.innerWidth
            window.addEventListener('resize', () => {
                this.window = window.innerWidth
            }) 
        
        },
        watch: {
            window(newValue) {
                if (newValue > 1400) {
                    this.width = 1000
                    this.height = 500
                }
                else if (newValue > 1000 && newValue < 1400) {
                    this.width = 800
                    this.height = 400
                }
                else if (newValue < 1000 && newValue > 600) {
                    this.width = 500
                    this.height = 300 
                } else if (newValue < 600) {
                    this.width = 400
                    this.height = 300
                }
            }
        }
    }

</script>

<style scoped>
.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.75);
}
dialog {
    position: fixed;
    max-width: 80vw;
    top: 10vh;
    margin: 0 auto;
    box-shadow: 0 2px 8px rgba(0,0,0,0.26);
    border-radius: 12px;
    padding: 1rem;
    background-color: rgb(255, 255, 255);
    z-index: 9999;
    border: none;
    height: auto;
}

.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 3%;
}

.modal-enter-active {
    animation: modal-in 0.4s ease-out;
}
.modal-leave-active {
    animation: modal-out 0.4s ease-in;
}
.title-label {
    font-size: 28px;
    letter-spacing: 0.4px;
    color: var(--brown30);
}
.content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5%;
}
.gallery {
    max-width: 200px;
    max-height: 200px;
}
img {
    width: 80px;
    height: 80px;
}
@keyframes modal-in {
    from {
        opacity: 0;
        transform: scale(0.8)
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}
@keyframes modal-out {
    from {
        opacity: 1;
        transform: scale(1)
    }
    to {
        opacity: 0;
        transform: scale(0.8);
    }
}
</style>