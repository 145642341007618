<template>
  <div class="carousel-wrapper">
      
        <vue-flux class="carousel-desktop" v-if="this.window > 500"
        :images="vfImages"
        :transitions="vfTransitions"
        :options="vfOptions"
        />

      <vue-flux class="carousel" v-else
        :images="vfImages"
        :transitions="vfTransitions"
        :options="vfOptions"
        />

  
  </div>
</template>


<script>
  import slider_1 from '@/assets/job_2/img2_4.jpg'
  import slider_2 from '@/assets/job_2/img2_7.jpg'
  import slider_3 from '@/assets/job_1/img1_3.jpg'
  import slider_4 from '@/assets/job_4/img4_3.jpg'
  import slider_5 from '@/assets/job_4/img4_2.jpg'
  import {VueFlux} from 'vue-flux';
  export default {
    components: {
      VueFlux
    },
    data() {
      return {
        vfOptions: {
         autoplay: true,
         delay: 3000,
         lazyLoadAfter: 5,
        },
        vfImages: [ slider_1, slider_2, slider_3, slider_4, slider_5 ],
        vfTransitions: [ 'fade', 'fade', 'fade', 'fade','fade' ], 
        labelsReady: false,
        window: null 
      }
    },  
    mounted() {
      setTimeout(() => {
        this.labelsReady = true
        this.$emit('carousel-loaded')
      }, 2000);
    },
    beforeMount() {
        this.window = window.innerWidth
        window.addEventListener('resize', () => {
            this.window = window.innerWidth
        }) 
    },
  }
</script>

<style scoped>
.left-enter-active {
  animation: left-enter .5s ease-in;
}
.right-enter-active {
  animation: right-enter .5s ease-in;
}
  .carousel-desktop {
    position: relative;
    width: 100%;
    border-radius: 20px;
    height: 70vh;
    min-height: 440px;
    animation: carousel-destkop-enter 2s ease-out forwards;
    
  }
  .carousel {
    position: relative;
    margin-top: 50px;
    width: 100%;
    height: 40vh;
    min-height: 80px;
    border-radius: 20px;

  }
  .left {
    position: absolute;
    height: 100%;
    top: -6%;
    left: 4%;
  }
  .right {
    position: absolute;
    width: 30vw;
    max-height: 10px;
    left: 70%;
    top: 0.2%;
  }
  .logo {
    margin-top: 12%;
    margin-left: -16%;
    
  }

@keyframes carousel-destkop-enter{
  0% {
    transform: scale(0);
    clip-path: circle(30%);
  }
  40% {
    transform: scale(2.5);
  }
  100% {
    transform: scale(1);
    clip-path: polygon(50% 0, 100% 20%, 100% 80%, 50% 100%, 0 80%, 0 20%);
  }
}

</style>