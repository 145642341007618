import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import PrimeVue from 'primevue/config';
import Lingallery from 'lingallery';
import Meta from 'vue-meta';
import '@mdi/font/css/materialdesignicons.css';
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import "primeflex/primeflex.css";


Vue.config.productionTip = false
Vue.component('lingallery', Lingallery);
Vue.use(Meta);

new Vue({
  vuetify,
  PrimeVue,
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  },
  render: h => h(App)
}).$mount('#app')
