<template>
    <div class="footer" :class="{'align-row': !isMobile, 'align-column': isMobile}">
        <div class="left-wrapper" :style="{width: isMobile ? '100%' : '50%', 'margin-top': isMobile ? '5%' : '0'}">
            <div class="header font-raleway"  :class="{'align-center': isMobile}"> Skontaktuj się z nami </div>
            <div class="icons" :class="{'align-center': isMobile}">
                <div class="row" > 
                    <v-icon class="ico" color="#ffffff"> mdi-map </v-icon>
                    <span class="footer-label font-raleway"> Kraków i okolice </span>
                </div>
                <div class="row" >
                    <v-icon class="ico" color="#ffffff"> mdi-phone </v-icon>
                    <a href="tel:+48518552202" class="footer-label font-raleway"> +48 518 552 202 </a>
                </div>
                <div class="row" >
                    <v-icon class="ico" color="#ffffff"> mdi-email </v-icon>
                    <a href="mailto:lb-wykonczenia@wp.pl" class="footer-label font-raleway"> lb-wykonczenia@wp.pl </a>
                </div>
            </div>
        </div>
        <div class="right-wrapper" :style="{width: isMobile ? '100%' : '50%'}">
    
            <div class="surface-card p-4 shadow-2 border-round w-full lg:w-6 card" :style="{'max-height': isMobile ? '800px' : '446px'}">
                <div class="text-center mb-3">
                    <div class="text-900 text-3xl font-medium mb-3 font-raleway">Wyślij do nas maila</div>
                    <span class="text-600 font-medium line-height-3 font-raleway">Odpowiemy do 24h :)</span>
                </div>
                <div>

                <form ref="form">
                    <div class="flex align-items-center justify-content-between mb-2" v-if="window > 630">
                        <div class="col">
                            <span class="text-900 font-medium mb-2 font-raleway"> Imię </span>
                            <InputText v-model="name" name="name" type="text" class="w-full" placeholder="Imię"/>
                        </div>

                        <div class="col">
                            <span class="text-900 font-medium mb-2 ml-1 font-raleway"> Email </span>
                            <InputText v-model="mailfrom" name="mailfrom" type="text" class="w-full ml-1" placeholder="Email"/>
                        </div>
                    </div>
                    
                    <label for="email1" class="block text-900 font-medium mb-2 font-raleway" v-if="window <= 630">Imie</label>
                    <InputText id="email1" v-model="name" name="name" type="text" class="w-full mb-3" placeholder="Temat" v-if="window <= 630" />

                    <label for="email1" class="block text-900 font-medium mb-2 font-raleway" v-if="window <= 630">Email</label>
                    <InputText v-model="mailfrom" id="email1" name="email" type="text" class="w-full mb-3" placeholder="Temat" v-if="window <= 630"/>

                    <label for="email1" class="block text-900 font-medium mb-2 font-raleway">Temat</label>
                    <InputText v-model="topic" id="email1" name="topic" type="text" class="w-full mb-3" placeholder="Temat" />

                    <label for="password1" class="block text-900 font-medium mb-2 font-raleway">Wiadomość</label>
                    <Textarea v-model="message" name="message" rows="3" cols="20" :autoResize="true" class="w-full mb-3 textarea" placeholder="Wiadomość"/>



                    <Button @click="sendMail()" label="Wyślij" :icon="!sending ? 'pi pi-send' : 'pi pi-spin pi-spinner'" class="w-full contact-button" :class="{'contact-done': send_done}"></Button>
                </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import InputText from 'primevue/inputtext';
    import Textarea from 'primevue/textarea';
    import Button from 'primevue/button';
    import emailjs from 'emailjs-com';
    export default {
        components: {
            InputText,
            Button,
            Textarea
        },
        data() {
            return {
                window: null,
                name: '',
                topic: '',
                mailfrom: '',
                message: '',
                sending: false,
                send_done: false
            }
        },
        methods: {
            sendMail() {
                this.sending = true
                setTimeout(() => {
                    this.sending = false
                    this.send_done = true
                }, 1000);
                try {
                    emailjs.sendForm('service_4a65u38', 'template_84kmmho', this.$refs.form,
                    'user_reRZL4p8kXaDSABdjXnk2', {
                    name: this.name,
                    topic: this.topic,
                    message: this.message,
                    mailfrom: this.mailfrom
                    })
                   

                } catch(error) {
                    console.log({error})
                }
                // Reset form field
                this.name = ''
                this.mailfrom = ''
                this.message = ''
                this.topic = ''
                },
        },
        computed: {
            isMobile() {
                return this.window < 1220 ? true : false
            }
        },
        beforeMount() {
            this.window = window.innerWidth
            window.addEventListener('resize', () => {
                this.window = window.innerWidth
            })
        },
    }
</script>

<style scoped>
.footer {
    position: relative;
    width: 100%;
    height: 100%;
}
.left-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.left-wrapper-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.right-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3% 0 3% 0;
    width: 50%;
    height: 100%;
}
.header {
    display: flex;
    justify-content: flex-start;
    width: 50%;
    color: white;
    font-size: 35px;
}
.icons {
    padding-top: 30px;
    display: flex;
    width: 50%;
    flex-direction: column;
}
.row {
    display: flex;
    flex-direction: row;
    min-width: 260px;
    padding: 10px;
}
.footer-label {
    font-size: 18px;
    color: white;
    padding-left: 1rem;
}
.card {
    margin-top: 5%;
    max-height: 446px;
    max-width: 600px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.4) !important;
}
.textarea {
    max-height: 85px;
}
.col {
    display: flex;
    flex-direction: column;
    padding: 0;
}
.contact-button {
    background-color: var(--brown30);
    border-color: var(--brown30);
}
.contact-button:hover {
    background-color: var(--brown40) !important;
    border-color: var(--brown40) !important;
}
.contact-done {
    background-color: var(--brown40) !important;
    pointer-events: none;
}
.align-column {
    display: flex;
    flex-direction: column;
}
.align-row {
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: row;
}
.align-center {
    display: flex;
    align-items: center;
    justify-content: center;
}
@media (max-width: 685px) {
  .card {
      max-width: 400px;
  }
}
@media (max-width: 400px) {
  .card {
      max-width: 100% !important;
  }
}


</style>