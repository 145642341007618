<template>
    <div>
        <div class="section1">
            <div class="shape">
                <div class="wrapper">
                    <div class="title">
                        O naszej firmie
                    </div>
                    <hr class="hr">
                    <div class="content">
                        Jesteśmy firmą zajmującą się wykończeniem wnętrz, która specjalizuje się w dostarczaniu innowacyjnych i kreatywnych rozwiązań dla naszych klientów. Działamy na rynku od ponad 5 lat i mamy doświadczenie zarówno w projektach mieszkaniowych, jak i komercyjnych.
                    </div>
                </div>     
            </div>
        </div>
        <div class="section2" id="section2-mobile">
            <div class="shape">
                <div class="top-wrapper">
                    <div class="title">
                        Wykończenie pod klucz
                    </div>
                    <hr class="hr">
                    <div class="content">
                        Staramy się zapewnić każdemu klientowi niestandardowe doświadczenie, przy jednoczesnym zachowaniu wysokiego poziomu jakości. Naszym klientom oferujemy szeroki zakres usług od zaprojektowania i remontu całego domu po samo doradztwo projektowe.
                    </div>
                </div>
                <div class="bottom-wrapper">
                    <div class="title">
                        Nasze usługi to zadowoleni klienci
                    </div>
                    <hr class="hr">
                    <div class="content">
                        Jesteśmy firmą oferującą szeroki zakres usług i z dumą możemy powiedzieć, że wszyscy nasi klienci są zadowoleni z tego, co zapewniamy. Działamy w branży od wielu lat i zawsze potrafiliśmy sprostać wymaganiom naszych klientów. Ciężko pracujemy, aby byli zadowoleni z tego, co od nas otrzymują.
                    </div>
                </div>    
            </div>
        </div>
        <div class="section3">
            <div class="shape">
                <div class="wrapper">
                    <div class="title">
                        Brak stałego cennika
                    </div>
                    <hr class="hr">
                    <div class="content">
                        Możemy zająć się wszystkim od początku do końca dzięki naszym rozwiązaniom wykończeniowym „pod klucz”. Nie posiadamy stałego cennika, cenę ustalamy indywidualnie po konsultacji. Skontaktuj się już dziś, aby uzyskać więcej informacji. Po konsultacji z naszym ekspertem tworzymy pakiet wykończeniowy dostosowany specjalnie dla Ciebie, który obejmuje wszystko.
                    </div>
                </div>     
            </div>
        </div>
        <div class="section4" id="section3-mobile">
            <div class="shape">
                <div class="wrapper">
                    <div class="title">
                        Przykładowe realizacje
                    <hr class="hr">
                    </div>
                    <div class="galeria">
                        <Galleria :value="gallery_1.images" :numVisible="5" class="gallery-element"
                            :showThumbnails="false" :showIndicators="true" :showItemNavigators="true" :showIndicatorsOnItem="true">
                            <template #item="slotProps">
                                <img :src="slotProps.item.img" style="width: 100%" />
                            </template>
                        </Galleria>
                    </div>
                    <div class="galeria">
                        <Galleria :value="gallery_2.images" :numVisible="5" class=""
                            :showThumbnails="false" :showIndicators="true" :showItemNavigators="true" :showIndicatorsOnItem="true">
                            <template #item="slotProps">
                                <img :src="slotProps.item.img" style="width: 100%" />
                            </template>
                        </Galleria>
                    </div>
                    <div class="galeria">
                        <Galleria :value="gallery_3.images" :numVisible="5" class=""
                            :showThumbnails="false" :showIndicators="true" :showItemNavigators="true" :showIndicatorsOnItem="true">
                            <template #item="slotProps">
                                <img :src="slotProps.item.img" style="width: 100%" />
                            </template>
                        </Galleria>
                    </div>
                </div>     
            </div>
        </div>
        <div class="section5" id="section4-mobile">
                <div class="wrapper">
                    <Footer />
                </div>     
        </div>
    </div>    
</template>

<script>
    import Galleria from 'primevue/galleria';
    import Footer from '@/components/Footer.vue'
    export default {
        components: {
            Galleria,
            Footer
        },
        data() {
            return {
                gallery_1: {
                    images: [], 
                },
                gallery_2: {
                    images: [],
                },
                gallery_3: {
                    images: [],
                }
            }
        },
        beforeMount() {
            for (let index = 0; index < 4; index++) {
                this.gallery_1.images[index] = {
                    img: require(`@/assets/job_1/img1_${index + 1}.jpg`)
                }   
            }
            for (let index = 0; index < 9; index++) {
                this.gallery_2.images[index] = {
                    img: require(`@/assets/job_2/img2_${index + 1}.jpg`)
                }   
            }
            for (let index = 0; index < 3; index++) {
                this.gallery_3.images[index] = {
                    img: require(`@/assets/job_3/img3_${index + 1}.jpg`)
                }   
            }
        },     
    }    

</script>

<style scoped>
.section1 {
    position: relative;
    background-color: white;
    height: 400px;
    width: 100%;
}

.section1 .wrapper {
    position: absolute;
    margin-top: 20%;
    padding: 0 10% 0 10%;
    display: flex;
    flex-direction: column;
    color: var(--brown30);
    height: auto;
}
.title {
    font-size: 25px;
}
.content {
    font-size: 18px;
}
.section2 {
  position: relative;
  background-color: white;
  min-height: 820px;
  width: 100%;
}
.section2 .shape {
  clip-path: polygon(0 6%, 100% 0, 100% 94%, 0% 100%);
  background-color: var(--blue30);
  min-height: 820px;
}
.section2 .top-wrapper {
    position: absolute;
    margin-top: 30%;
    padding: 0 10% 0 10%;
    display: flex;
    flex-direction: column;
    color: white;
    height: auto;
}
.section2 .bottom-wrapper {
    position: absolute;
    margin-top: 120%;
    padding: 0 10% 0 10%;
    display: flex;
    flex-direction: column;
    color: white;
    height: auto;
}
.hr {
  transform: rotate(-0.84deg);
}
.section3 {
    position: relative;
    height: 450px;
    width: 100%;
}
.section3 .shape {
    clip-path: polygon(0 6%, 100% 0, 100% 94%, 0% 100%);
    background-color: white;
    height: 450px;
}
.section3 .wrapper {
    position: absolute;
    margin-top: 20%;
    padding: 0 10% 0 10%;
    display: flex;
    flex-direction: column;
    color: var(--brown30);
    height: auto;
}
.section4 {
    position: relative;
    background: linear-gradient(0deg, var(--blue20), rgb(252, 252, 252) 80%);
    height: 1500px;
    width: 100%;
}
.section4 .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    padding-bottom: 70px;
}
.section4 .hr {
    width: 80% !important;
    transform: rotate(0);
}
.section4 .shape {
    clip-path: polygon(0 6%, 100% 0, 100% 94%, 0% 100%);
    background-color: var(--brown30);
    height: 1500px;
}
.section4 .wrapper {
    position: absolute;
    margin-top: 20%;
    padding: 0 10% 0 10%;
    display: flex;
    flex-direction: column;
    color: white;
    height: auto;
}
.section5 {
    position: relative;
    background-color: var(--blue20);
    min-height: 60vh;
    width: 100%;
}
.section5 .wrapper {
    position: absolute;
    padding: 0 10% 0 10%;
    background-color: var(--blue20);
    color: white;
    min-height: 60vh;
    width: 100%;
}
.galeria {
    width: 80vw;
    height: 400px;
}
.gallery-element {
    max-width: 640px;
}
img {
    width: 80vw;
    height: 300px;
}
.thumbnail {
    width: 100% !important;
    height: 40px !important;
}
.galeria:deep(.p-galleria-thumbnail-container) {
    background-color: aqua !important;
}
.btn {
    margin: 0 auto;
    background-color: var(--blue30);
    border-color: var(--blue30);
    width: 50%;
}
.btn:hover {
    background-color: var(--blue20) !important;
    border-color: var(--blue20) !important;
}

</style>