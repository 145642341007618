<template>
    <transition name="section_1_enter">
      <div class="body-wrapper">
        <div class="section1">

          <div class="top-wrapper">
            <div class="top-title" :style="{'font-size': fontSize.title}">
              O naszej firmie
            </div>
            <hr class="hr brown">
            <div class="top-content" :style="{'font-size': fontSize.content}">
              Jesteśmy firmą zajmującą się wykończeniem wnętrz, która specjalizuje się w dostarczaniu innowacyjnych i kreatywnych rozwiązań dla naszych klientów. Od ponad 7 lat staramy się zapewnić naszym klientom możliwie najwyższy standard wykończenia wnętrz. Na pierwszym miejscu stawiamy profesjonalizm i terminowość. 
            </div>
          </div>

          <div class="bottom-wrapper" id="section2">
            <div class="title" :style="{'font-size': fontSize.title}">
              Wykończenie pod klucz
            </div>
            <hr class="hr white">
            <div class="content" :style="{'font-size': fontSize.content}">
              Staramy się zapewnić każdemu klientowi niestandardowe doświadczenie, przy jednoczesnym zachowaniu wysokiego poziomu jakości. Naszym klientom oferujemy szeroki zakres usług od wykonczenia po remont całego domu, mieszkania. Współpracujemy również z stolarzem, dzięki czemu czas realizacji zlecenia jest o wiele szybszy.
            </div>
          </div>

        </div>
        <div class="section2" id="section2">

          <div class="top-wrapper">
            <div class="top-title" :style="{'font-size': fontSize.title}">
              Nasze usługi to zadowoleni klienci
            </div>
            <hr class="hr brown">
            <div class="top-content" :style="{'font-size': fontSize.content}">
              Jesteśmy firmą oferującą szeroki zakres usług i z dumą możemy powiedzieć, że wszyscy nasi klienci są zadowoleni z tego, co zapewniamy. Działamy w branży od wielu lat i zawsze potrafiliśmy sprostać wymaganiom naszych klientów. Ciężko pracujemy, aby byli zadowoleni z tego, co od nas otrzymują.
            </div>
          </div>

          <div class="bottom-wrapper">
            <div class="title" :style="{'font-size': fontSize.title}">
              Brak stałego cennika
            </div>
            <hr class="hr white">
            <div class="content" :style="{'font-size': fontSize.content}">
              Nie posiadamy stałego cennika, cenę ustalamy indywidualnie po konsultacji. Dostosowujemy się do potrzeb Klienta i udzielamy gwarancji na wykonane usługi. Pomagamy również naszym kontrahentom w doborze materiałów oraz zagospodarowaniu przestrzeni. Skontaktuj się już dziś, aby uzyskać więcej informacji.
            </div>
          </div>
      
        </div>

        <GalleryDialog v-if="show_gallery_dialog" :open="show_gallery_dialog" :gallery="job" :photos="num_of_photos" @close="closeGalleryDialog()" />

        
        <div class="section3" id="section3">
          <div class="shape">
            <div class="gallery-label">
              <span class="title" :style="{'font-size': fontSize.title}"> Przykładowe realizacje </span>
              <hr class="hr white">
            </div>
            <div class="gallery">
                <div class="wrapper">
                  <img src="../assets/job_1/img1_1.jpg" @click="openGalleryDialog(1, 5)" class="img" alt="">
                  <img src="../assets/job_2/img2_1.jpg" @click="openGalleryDialog(2, 9)" class="img" alt="">
                  <img src="../assets/job_3/img3_1.jpg" @click="openGalleryDialog(3, 3)" class="img" alt="">
                  <img src="../assets/job_4/img4_1.jpg" @click="openGalleryDialog(4, 3)" class="img" alt="">
                  <img src="../assets/job_5/img5_2.jpg" @click="openGalleryDialog(5, 6)" class="img" alt="">
                  <img src="../assets/job_6/img6_7.jpg" @click="openGalleryDialog(6, 9)" class="img" alt="">
                  <img src="../assets/job_7/img7_3.jpg" @click="openGalleryDialog(7, 6)" class="img" alt="">
                  <img src="../assets/job_8/img8_4.jpg" @click="openGalleryDialog(8, 4)" class="img" alt="">
                  <img src="../assets/job_9/img9_1.jpg" @click="openGalleryDialog(9, 3)" class="img" alt="">
                  <img src="../assets/job_10/img10_1.jpg" @click="openGalleryDialog(10, 3)" class="img" alt="">
                </div>
            </div>
          </div>
        </div>
        <div class="footer" id="section4">
          <Footer />
        </div>
      </div>  
    </transition>   
</template>

<script>
    import GalleryDialog from '@/components/GalleryDialog.vue'
    import Footer from '@/components/Footer.vue'
    export default {
      components: {
        GalleryDialog,
        Footer
      },
      data() {
        return {
          angle1: {angle: 'rotate(-32deg)', marign_bottom: '-22%'},
          show_label_1: false,
          show_gallery_dialog: false,
          job: '',
          num_of_photos: null,
        }
    },
  methods: {
    swapLabel1() {
      this.show_label_1 = !this.show_label_1
    },
    openGalleryDialog(job, photos) {
      this.job = job
      this.num_of_photos = photos
      this.show_gallery_dialog = true
    },
    closeGalleryDialog() {
      this.show_gallery_dialog = false
    }
  },
  computed: {
    labelAngle() {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return {angle: 'rotate(-54deg)', marign_bottom: '-42%'}
          case 'sm': return {angle: 'rotate(-40deg)', marign_bottom: '-29%'}
          case 'md': return {angle: 'rotate(-32deg)', marign_bottom: '-22%'}
          case 'lg': return {angle: 'rotate(-23deg)', marign_bottom: '-14%'}
        } return {angle: 'rotate(-32deg)', marign_bottom: '-22%'}
    },
    fontSize() {
        if (this.$vuetify.breakpoint.width < 1070 && this.$vuetify.breakpoint.width > 840) {
          return {title: '30px', content: '22px'}
        }
        else if (this.$vuetify.breakpoint.width < 840 && this.$vuetify.breakpoint.width > 650) {
          return {title: '28px', content: '20px'}
        }
        else if (this.$vuetify.breakpoint.width < 650) {
          return {title: '25px', content: '16px'}
        }
        return {title: '40px', content: '24px'}
    }
  }   
    }
</script>

<style scoped>
.section_1_enter-enter-active {
  animation: section-1-enter 0.4s ease-in
}

@keyframes section-1-enter {
  from {
    opacity: 0%;
  }
  to {
    opacity: 100%;
  }
}


.section1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  bottom: 130px;
  height: 800px;
  width: 100%;
  transition: all 0.3s ease-in;
  background: linear-gradient(to top left, var(--blue30) calc(50% - 1px), rgb(255, 255, 255) , rgba(255, 255, 255, 0.979) calc(50% + 1px) );
}

.section2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-top: -130px;
  height: 750px;
  width: 100%;
  background: linear-gradient(to top right, rgb(255, 255, 255) calc(50% - 1px), rgb(255, 255, 255) , var(--blue30) calc(50% + 1px) )
}
.section3 {
  position: relative;
  background: linear-gradient(0deg, var(--blue20), rgb(255, 255, 255) 80%);
  height: 830px;
  width: 100%;
}
.section3 .shape {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  clip-path: polygon(0 6%, 100% 0, 100% 94%, 0% 100%);
  background-color: var(--brown30);
  height:830px;
}
.gallery-label {
  width: 100%;
  height: 140px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;

}
.section3 hr {
  width: 40%;
}
.gallery {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5%;
  width: 100%;
  max-height: 60vh;
}
.gallery .wrapper {
  display: flex;
  position: relative;
  flex-grow: 1;
  width: 40%;
  max-width: 1100px;
  min-width: 520px;
  margin-bottom: 24%;
  max-height: 30%;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-gap: 1.5vmin;
}
.footer {
  position: relative;
  background-color: var(--blue20);
  min-height: 50vh;
  width: 100%;
}
.container1 {
  position: relative;
  top:30px;
  left:0;
  width: 200px;
  height: 200px;
  background-color: aqua;
  z-index: 1;
}
.box {
  position: absolute;
  width: 100px;
  height: 100px;
  background-color: aqua;
  top:30px;
  left: 20px;
}
.bottom-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 30vw;
  margin-right: 3%;
  margin-top: 18%;
}
.section2 .bottom-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 30vw;
  margin-bottom: 45%;
}
.top-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 30vw;
  word-break: keep-all;
  margin-bottom: 10%;
  margin-left: 5%;
}

.section2 .top-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 30vw;
  margin-top: 40%;
  margin-bottom: 25%;
}
.top-title {
  color: var(--brown30);
  font-size: 30px;
  margin-bottom: 1%;
}
.title {
  color: rgb(255, 255, 255);
  font-size: 50px;
  margin-bottom: 1%;
}
.top-content {
  margin-top: 2%;
  color: var(--brown30);
  font-size: 16px;
}
.content {
  margin-top: 2%;
  color: rgb(255, 255, 255);
  font-size: 25px;
}
.hr {
  transform: rotate(-0.84deg);
  height: 1.5px;
}

@media (max-width: 530px) {
  .top-wrapper {
    margin-bottom: 35%;
  }
  .top-content {
    font-size: 15px;
  }
  .section2 .bottom-wrapper {
    margin-bottom: 100% !important;
  }
}
@media (max-width: 840px) {
  .section2 .bottom-wrapper {
    margin-bottom: 60%;
  }
}
@media (max-width: 720px) {
  .section2 .top-wrapper {
    margin-top: 60%;
  }
  .bottom-wrapper {
    margin-bottom: -20%;
  }
}

 .img {
  z-index: 1;
  grid-column: span 2;
  max-width: 100%;
  margin-bottom: -52%;
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  transform: scale(1);
  transition: all 0.25s;
}
 .img:nth-child(7n + 1) {
	grid-column: 2 / span 2;
}
 .img:hover {
	z-index: 2;
	transform: scale(1.5);
  cursor: pointer;
}
.btn {
    margin: 0 auto;
    background-color: var(--blue30);
    border-color: var(--blue30);
    width: 50%;
}
.btn:hover {
    background-color: var(--blue20) !important;
    border-color: var(--blue20) !important;
}
.white {
  background-color: white
}
.brown {
  background-color: var(--brown30)
}


</style>